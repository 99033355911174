import Vue from "vue";
import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";

import config from "@/config";
import i18n from "@/i18n";

import Store from "../index";
import AccountModule from "./account";

export enum EPDAccess_Type {
  INDIRECT = "IndirectAccess",
  EMERGENCY = "EmergencyAccess",
}

export interface EPDAccess {
  type: EPDAccess_Type;
  instant: string;
  subject: string;
  firstName: string;
  lastName: string;
  groupOid?: string;
  groupName?: string;
}

export interface NotificationSettings {
  emergencyAccess: boolean;
  indirectAccessor: boolean;
}

const accountState = getModule(AccountModule);
const authHeaderFunction = async function (): Promise<string | null> {
  try {
    return accountState.getAuthenticationHeader();
  } catch (error) {
    Vue.notify({
      text: i18n.t("toasts.auth.tokenFetchFail").toString(),
      type: "error",
      duration: -1,
    });
    return null;
  }
};

@Module({
  dynamic: true,
  store: Store,
  name: "notifications",
  namespaced: true,
})
export default class NotificationsModule extends VuexModule {
  public notifications: EPDAccess[] | null = null;
  public isLoading: boolean = false;

  public settingsIsLoading: boolean = false;
  public notificationSettings: NotificationSettings | null = null;

  // Parameter to track whether or not the notification modal has been shown
  // to the user during this session.
  public hasShownNotification: boolean = false;

  @Mutation
  setHasShownNotification(hasShown: boolean) {
    this.hasShownNotification = hasShown;
  }

  @Mutation
  clearNotifications() {
    this.notifications = null;
  }

  @Mutation
  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  setNotifications(notifications: EPDAccess[] | null) {
    this.notifications = notifications;
  }

  @Mutation
  setSettingsLoading(isLoading: boolean) {
    this.settingsIsLoading = isLoading;
  }

  @Mutation
  private setNotificationSettings(notificationSettings: NotificationSettings | null) {
    this.notificationSettings = notificationSettings;
  }

  @Mutation
  clearNotificationSettings() {
    this.notificationSettings = null;
  }

  @Action({ rawError: true })
  async fetchNotificationSettings() {
    const url = config.eprServices.notificationSettings;

    this.setSettingsLoading(true);

    const authHeader = await authHeaderFunction();
    if (authHeader == null) {
      this.setSettingsLoading(false);
      this.setNotificationSettings(null);
      throw new Error("No auth Header");
    }

    const response = await window.fetch(encodeURI(url), {
      method: "GET",
      headers: {
        Authorization: authHeader,
      },
    });

    if (!response.ok) {
      this.setSettingsLoading(false);
      this.setNotificationSettings(null);
      throw new Error("Response not OK");
    }

    const settings = (await response.json()) as NotificationSettings;

    this.setSettingsLoading(false);
    this.setNotificationSettings(settings);
  }

  @Action({ rawError: true })
  async putNotificationSettings(settings: NotificationSettings) {
    const url = config.eprServices.notificationSettings;

    this.setSettingsLoading(true);

    const authHeader = await authHeaderFunction();
    if (authHeader == null) {
      this.setSettingsLoading(false);
      this.setNotificationSettings(null);
      throw new Error("No auth Header");
    }

    const response = await window.fetch(encodeURI(url), {
      method: "PUT",
      headers: {
        Authorization: authHeader,
      },
      body: JSON.stringify(settings),
    });

    if (!response.ok) {
      this.setSettingsLoading(false);
      this.setNotificationSettings(null);
      throw new Error("Response not OK");
    } else {
      this.setSettingsLoading(false);
      this.setNotificationSettings(settings);
    }
  }

  @Action({ rawError: true })
  async fetchNotifications() {
    /* let url = config.eprServices.notification;

    const authHeader = await authHeaderFunction();
    if (authHeader == null) {
      this.setLoading(false);
      this.setNotifications(null);
      throw new Error("No auth Header");
    }

    const response = await window.fetch(encodeURI(url), {
      method: "GET",
      headers: {
        Authorization: authHeader,
      },
    });

    if (!response.ok) {
      this.setLoading(false);
      this.setNotifications(null);
      throw new Error("Response not OK");
    }

    const accesses = (await response.json()) as EPDAccess[];

    // TODO: Remove
    // const accesses = [
    //   {
    //     type: "IndirectAccess",
    //     instant: "2020-01-02T13:18:54.024Z",
    //     subject: "7601002469191",
    //     firstName: "Sarah",
    //     lastName: "Walker",
    //   },
    //   {
    //     type: "IndirectAccess",
    //     instant: "2020-03-03T09:01:34.024Z",
    //     subject: "7601002469191",
    //     firstName: "John",
    //     lastName: "Casey",
    //   },
    //   {
    //     type: "IndirectAccess",
    //     instant: "2019-09-01T16:44:50.024Z",
    //     subject: "7601002469191",
    //     firstName: "Chuck",
    //     lastName: "Bartowski",
    //   },
    //   {
    //     type: "EmergencyAccess",
    //     instant: "2020-04-23T20:12:14.024Z",
    //     subject: "7601002469191",
    //     firstName: "Ann",
    //     lastName: "Emergency",
    //   },
    //   {
    //     type: "EmergencyAccess",
    //     instant: "2020-02-05T11:12:13.024Z",
    //     subject: "7601002469191",
    //     firstName: "Emergency",
    //     lastName: "Andrews",
    //   },
    // ] as EPDAccess[]; 

    this.setLoading(false);
    this.setNotifications(accesses); */

    this.setLoading(false);
    this.setNotifications([]);
  }

  get indirectAccesses(): EPDAccess[] {
    if (this.notifications) {
      return this.notifications.filter(
        (notification) => notification.type == EPDAccess_Type.INDIRECT
      );
    }
    return [];
  }

  get emergencyAccesses(): EPDAccess[] {
    if (this.notifications) {
      return this.notifications
        .filter((notification) => notification.type == EPDAccess_Type.EMERGENCY)
        .sort((a, b) => b.instant.localeCompare(a.instant));
    }
    return [];
  }
}
