import { Module, Mutation, VuexModule } from "vuex-module-decorators";

import Store from "../index";

@Module({
  dynamic: true,
  store: Store,
  name: "sidebar",
  namespaced: true,
})
export default class SidebarModule extends VuexModule {
  isSidebarOpen: boolean = true;

  @Mutation
  setIsSidebarOpen(isSidebarOpen: boolean) {
    this.isSidebarOpen = isSidebarOpen;
  }
}
