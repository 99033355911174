
import Button from "primevue/button";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    Button,
  },
})
export default class BackButton extends Vue {
  @Prop({ default: null }) click!: Function;

  onClick() {
    if (this.click) {
      this.click();
    } else {
      this.$router.go(-1);
    }
  }
}
