import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import Store from "../index";

@Module({
  dynamic: true,
  store: Store,
  name: "view",
  namespaced: true,
})
export default class ViewModule extends VuexModule {
  isFullscreen: boolean = false;
  isSmallScreen: boolean = window.innerWidth < 768;
  isExtraSmallScreen: boolean = window.innerWidth < 640;

  @Action
  initListeners() {
    window.addEventListener("resize", this.handleResize);
  }

  @Action
  removeListeners() {
    window.removeEventListener("resize", this.handleResize);
  }

  // NOTE: maybe merge the two store to avoid calling other states
  @Action
  private handleResize(event: any) {
    this.setIsSmallScreen(event.target.innerWidth < 768);
    this.setIsExtraSmallScreen(event.target.innerWidth < 640);
    this.context.commit("sidebar/setIsSidebarOpen", event.target.innerWidth >= 1024, {
      root: true,
    });
  }

  @Mutation
  setShowFullscreen(fullscreen: boolean) {
    this.isFullscreen = fullscreen;
  }

  @Mutation
  setIsSmallScreen(smallScreen: boolean) {
    this.isSmallScreen = smallScreen;
  }

  @Mutation
  setIsExtraSmallScreen(extraSmallScreen: boolean) {
    this.isExtraSmallScreen = extraSmallScreen;
  }
}
