import { IdentityProvider, OAuth2Configuration, Role } from "./global";

declare global {
  interface Window {
    __env__: Record<any, string | undefined>;
  }
}

window.__env__ = process.env || window.__env__ || {};

const meta = document.getElementsByTagName("meta");
for (let i = 0; i < meta.length; i++) {
  const element = meta.item(i);
  if (element?.getAttribute("data-name") == "env") {
    window.__env__ = Object.assign(
      window.__env__,
      JSON.parse(element.getAttribute("data-content")!)
    );
  }
}

const requiredEnvVars = [
  "VUE_APP_API_PHIDY",
  "VUE_APP_API_APHINITY",
  "VUE_APP_API_EPD_SERVICES",
  "VUE_APP_HOME_COMMUNITY_ID",
  "VUE_APP_HOME_COMMUNITY_NAME",
  "VUE_APP_HOME_COMMUNITY_PREFIX",
  "VUE_APP_LOCAL_AA",
  "VUE_APP_MTCAPTCHA_SITE_KEY",
];

const missingEnvVars = [];
for (const key of requiredEnvVars) {
  if (window.__env__[key] === undefined) {
    missingEnvVars.push(key);
  }
}

if (missingEnvVars.length > 0) {
  const errorMessage = `Undefined mandatory environment variables:\n${missingEnvVars.join("\n")}`;
  window.alert(errorMessage);
  throw new Error(errorMessage);
}

const AUTH_ADAPTER = window.__env__.VUE_APP_API_PHIDY?.replace(/\/$/, "");
const AD_ADAPTER = window.__env__.VUE_APP_API_APHINITY?.replace(/\/$/, "");
const EPR_ADAPTER = window.__env__.VUE_APP_API_EPD_SERVICES?.replace(/\/$/, "");

/**
 * Returns the OAuth2 configuration for the given provider.
 *
 * @param provider The identity provider to get the configuration for.
 * @returns The OAuth2 configuration for the given provider or null if the configuration is incomplete.
 */
function openIDConfigurationFor(provider: IdentityProvider): OAuth2Configuration | null {
  // Parse process.env in the format of VUE_APP_OAUTH2_{PROVIDER}_{KEY}
  const prefix = `VUE_APP_OAUTH2_${provider.toUpperCase()}`;
  const tenant = window.__env__[`${prefix}_TENANT`];
  if (!tenant) {
    return null;
  }

  const configuration = {
    authEndpoint:
      window.__env__[`${prefix}_AUTH`] || `${AUTH_ADAPTER}/tenant/${tenant}/openid-connect/auth`,
    tokenEndpoint:
      window.__env__[`${prefix}_TOKEN`] || `${AUTH_ADAPTER}/tenant/${tenant}/openid-connect/token`,
    logoutEndpoint:
      window.__env__[`${prefix}_LOGOUT`] ||
      `${AUTH_ADAPTER}/tenant/${tenant}/openid-connect/logout`,
    sessionEndpoint:
      window.__env__[`${prefix}_SESSION`] || `${AUTH_ADAPTER}/tenant/${tenant}/session`,
    redirectUri:
      window.__env__[`${prefix}_REDIRECT_URI`] || window.__env__.VUE_APP_OAUTH2_REDIRECT_URI,
    clientId: window.__env__[`${prefix}_CLIENT_ID`] || window.__env__.VUE_APP_OAUTH2_CLIENT_ID,
    clientSecret: window.__env__[`${prefix}_CLIENT_SECRET`],
    scopes: (window.__env__[`${prefix}_SCOPES`] || "").split(" "),
    pkce: JSON.parse(window.__env__[`${prefix}_PKCE`] || "false"),
    prompt: window.__env__[`${prefix}_PROMPT`] || null,
  };

  // Check if all required fields are set in accordance to the OAuth2Configuration interface
  for (const key of Object.keys(configuration)) {
    // Handle nullable fields
    if (key === "prompt" || key === "pkce" || key == "clientSecret") continue;

    if (!configuration[key as keyof OAuth2Configuration]) return null;
  }

  return configuration as OAuth2Configuration;
}

export default {
  epdCertified: JSON.parse(window.__env__.VUE_APP_EPD_CERTIFIED || "false"),
  i18n: {
    override: JSON.parse(window.__env__.VUE_APP_I18N_OVERRIDE || "{}"),
    availableLocales: ["de-ch", "fr-ch", "it-ch", "en-us"],
  },
  feature: {
    vaccination: JSON.parse(window.__env__.VUE_APP_FEATURE_VACCINATION || "true"),
    patientOnboarding: JSON.parse(window.__env__.VUE_APP_FEATURE_PATIENT_ONBOARDING || "true"),
    professionalOnboarding: JSON.parse(
      window.__env__.VUE_APP_FEATURE_PROFESSIONAL_ONBOARDING || "true"
    ),
    externalPatientRegistration: JSON.parse(
      window.__env__.VUE_APP_FEATURE_EXTERNAL_PATIENT_REGISTRATION || "true"
    ),
  },
  session: {
    disableSessionConstraints: JSON.parse(
      window.__env__.VUE_APP_SESSION_DISABLE_CONTRAINTS || "false"
    ),
    idleTimeout: +(window.__env__.VUE_APP_SESSION_IDLE_TIMEOUT || 15 * 60),
    maxAge: +(window.__env__.VUE_APP_SESSION_MAX_AGE || 60 * 60),
    honorIDTokenExpiration: JSON.parse(window.__env__.VUE_APP_SESSION_HONOR_ID_TOKEN_EXP || "true"),
    documentLimit: +(window.__env__.VUE_APP_SESSION_DOCUMENT_LIMIT || 2),
  },
  openid: {
    TestIDP: openIDConfigurationFor("TestIDP"),
    TrustID: openIDConfigurationFor("TrustID"),
    SwissID: openIDConfigurationFor("SwissID"),
    HIN: openIDConfigurationFor("HIN"),
  } satisfies Record<IdentityProvider, OAuth2Configuration | null>,
  r4: {
    documents: {
      referenceEndpoint: `${AD_ADAPTER}/r4/DocumentReference`,
      upload: `${AD_ADAPTER}/r4/`,
    },
    binary: {
      downloadZip: `${AD_ADAPTER}/r4/Binary/$zip`,
    },
    practitionerRole: `${AD_ADAPTER}/r4/PractitionerRole`,
    organization: `${AD_ADAPTER}/r4/Organization`,
    consent: `${AD_ADAPTER}/r4/Consent`,
    auditEvent: `${AD_ADAPTER}/r4/AuditEvent`,
    patients: `${AD_ADAPTER}/r4/Patient`,
    pixm: `${AD_ADAPTER}/r4/Patient/$ihe-pix`,
  },
  eprServices: {
    assistedPerson: `${EPR_ADAPTER}/v1/AssistedPerson`,
    representedPerson: `${EPR_ADAPTER}/v1/RepresentedPerson`,
    parentOrganization: `${EPR_ADAPTER}/v1/ParentOrganization`,
    notification: `${EPR_ADAPTER}/v1/Notification`,
    conversion: `${EPR_ADAPTER}/v1/Conversion`,
    vaccinationRequest: `${EPR_ADAPTER}/v1/VaccinationRequest`,
    notificationSettings: `${EPR_ADAPTER}/v1/Setting/Notifications`,
    patientRecord: `${EPR_ADAPTER}/v1/Patients/Epr`,
    patient: `${EPR_ADAPTER}/v1/Patient`,
    practitioner: `${EPR_ADAPTER}/v1/Practitioner`,
    relatedPerson: `${EPR_ADAPTER}/r4/RelatedPerson`,
  },
  homeCommunity: {
    id: window.__env__.VUE_APP_HOME_COMMUNITY_ID,
    name: window.__env__.VUE_APP_HOME_COMMUNITY_NAME,
    prefix: window.__env__.VUE_APP_HOME_COMMUNITY_PREFIX,
  },
  assigningAuthorities: {
    spid: "2.16.756.5.30.1.127.3.10.3",
    local: window.__env__.VUE_APP_LOCAL_AA,
  },
  documents: {
    filter: {
      upload: ((window.__env__.VUE_APP_DOCUMENT_FILTER_UPLOAD as string) || "pdf-a").split(","),
    },
    maxSize: +(window.__env__.VUE_APP_DOCUMENT_SIZE_MAX || 1000),
  },
  role: window.__env__.VUE_APP_MODE == "HCP" ? Role.HealthcareProfessional : Role.Patient,
  submissionSet: { authorRoleSystem: "1.3.6.1.4.1.21367.2017.2.5.63" },
  mtcaptchaConfig: {
    sitekey: window.__env__.VUE_APP_MTCAPTCHA_SITE_KEY,
    widgetSize: "mini",
    render: "explicit", // necessary to load when Vue-Component gets created. Was done this way in MTCaptcha Vue Demo.
    lang: "de", // default lang
    theme: "neowhite",
    renderQueue: [],
    autoFormValidate: true,
    enableTestMode: window.__env__.VUE_APP_MTCAPTCHA_TEST_KEY || null,
  },
  debug: {
    staticSPID: window.__env__.VUE_APP_DEBUG_STATIC_SPID || null,
    disableRoleCheck: JSON.parse(window.__env__.VUE_APP_DEBUG_DISABLE_ROLE_CHECK || "false"),
    commitHash: window.__env__.VUE_APP_DEBUG_COMMIT_HASH || "",
    versionTag: window.__env__.VUE_APP_DEBUG_VERSION_TAG || "",
  },
  theme: {
    primary: window.__env__.VUE_APP_THEME_PRIMARY || "#1266AD",
  },
};
