
/* Do NOT remove the dayjs locale imports, otherwise the localizedFormat methods won't work. */
import { nameCase } from "@foundernest/namecase";
import dayjs from "dayjs";
import "dayjs/locale/de-ch";
import "dayjs/locale/fr-ch";
import "dayjs/locale/it-ch";
import Avatar from "primevue/avatar";
import Button from "primevue/button";
import Ripple from "primevue/ripple";
import { Component, Emit, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import config from "@/config";
import AccountModule from "@/store/modules/account";
import SidebarModule from "@/store/modules/sidebar";
import { getInitials } from "@/utils";

@Component({
  components: {
    Avatar,
    Button,
  },
  directives: {
    ripple: Ripple,
  },
})
export default class ActiveUser extends Vue {
  private readonly sidebarState: SidebarModule = getModule(SidebarModule);
  private readonly accountState: AccountModule = getModule(AccountModule);

  private isAvatarHovered = false;

  get avatarIcon(): string | null {
    if (this.isAvatarHovered) {
      return "pi pi-sign-out";
    }

    return null;
  }

  get avatarLabel() {
    if (!this.isAvatarHovered) {
      return getInitials(this.name);
    }
    return null;
  }

  get name(): string {
    return this.accountState.currentUser?.name || "";
  }

  get nameShort() {
    // TODO: more sophisticated method to match the available drawer width
    // return getShortName(this.name);
    return nameCase(this.name.split(" ")[0], { lazy: false });
  }

  private timer: number | null = null;
  private accessibleTimer: number | null = null;

  private countdown: { display: string; datetime: string } = { display: "", datetime: "" };
  private accessibleCountdown: { display: string; datetime: string } = {
    display: "",
    datetime: "",
  };

  @Emit("logout")
  logout() {
    this.clearTimer();
  }

  created() {
    if (this.accountState.expiresAt) {
      this.setupLogoutTimer();
    }
  }

  destroyed() {
    this.clearTimer();
  }

  get expiresAt() {
    return this.accountState.idleTimeout || this.accountState.expiresAt;
  }

  get disableSessionContraints() {
    return config.session.disableSessionConstraints;
  }

  updateSession() {
    this.accountState.updateSessionTimeout();
  }

  setupLogoutTimer() {
    if (this.timer) {
      return;
    }

    if (!this.disableSessionContraints && this.expiresAt) {
      const timer = () => {
        if (this.expiresAt) {
          const now = dayjs();
          var expirationDate = dayjs(this.expiresAt);

          if (now.isAfter(expirationDate)) {
            return this.updateSession();
          }

          const remaining = dayjs(expirationDate.diff(now));

          this.countdown = {
            display: remaining.locale(this.$i18n.locale).format("m:ss"),
            datetime: remaining.locale(this.$i18n.locale).format("[PT]m[M]s[S]"),
          };
        }
      };

      timer();
      this.timer = window.setInterval(timer, 500);

      this.accessibleCountdown = this.countdown;

      this.accessibleTimer = window.setInterval(() => {
        this.accessibleCountdown = this.countdown;
      }, 60000);
    }
  }

  clearTimer(): void {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }

    if (this.accessibleTimer) {
      clearInterval(this.accessibleTimer);
      this.accessibleTimer = null;
    }
  }

  @Watch("expiresAt")
  onExpiresAtChanged(
    newValue: string | null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    oldValue: string | null
  ): void {
    // this.clearTimer();

    if (newValue) {
      this.setupLogoutTimer();
    } else {
      this.clearTimer();
      this.countdown = { display: "", datetime: "" };
    }
  }
}
