export function base64DecodeUnicode(input: string) {
  return decodeURIComponent(
    window
      .atob(input)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
}

export function base64UrlDecodeUnicode(input: string) {
  input = input.replace(/-/g, "+").replace(/_/g, "/");
  while (input.length % 4) input += "=";

  return decodeURIComponent(
    window
      .atob(input)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
}

export function bufferToHex(buffer: ArrayBuffer) {
  return [...new Uint8Array(buffer)].map((b) => b.toString(16).padStart(2, "0")).join("");
}

export function hexToBytes(hex: String): ArrayBuffer {
  const bytes = [];
  for (let c = 0; c < hex.length; c += 2) bytes.push(parseInt(hex.substring(c, c + 2), 16));
  return new Uint8Array(bytes);
}

export async function base64_encode(data: ArrayBuffer): Promise<string> {
  // Use a FileReader to generate a base64 data URI
  const base64url: string = await new Promise((r) => {
    const reader = new FileReader();
    reader.onload = () => r(reader.result as string);
    reader.readAsDataURL(new Blob([data]));
  });

  /*
  The result looks like 
  "data:application/octet-stream;base64,<your base64 data>", 
  so we split off the beginning:
  */
  return base64url.split(",", 2)[1];
}

export function getInitials(fullName: string) {
  const initials = fullName.split(" ").map((name) => name[0]);
  if (!initials) {
    return "";
  }

  if (initials.length > 2) {
    return `${initials[0]}${initials.slice(-1)}`.toUpperCase();
  }

  return initials.join("").toUpperCase();
}

export function getShortName(fullName: string, maxLength = 20) {
  if (!fullName) {
    return "";
  }

  let returnName = fullName;

  const names = fullName.split(" ");
  if (!names) {
    return "";
  }

  let initials = "";
  if (names.length > 2) {
    initials = names
      .slice(1, -1)
      .map((middleName) => `${middleName[0]}.`)
      .join(" ");
    returnName = `${names[0]} ${initials} ${names.slice(-1)}`;
  }

  if (returnName.length > maxLength) {
    returnName = `${names[0][0]}. ${initials} ${names.slice(-1)}`;
  }

  return returnName;
}

export function setCookie(name: string, value: string, seconds: number) {
  let expires = "";
  if (seconds) {
    const date = new Date();
    date.setTime(date.getTime() + seconds * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ""}${expires}; path=/`;
}

export function getCookie(name: string) {
  const cookieName = `${name}=`;
  const cookieArray = document.cookie.split(";");
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) == " ") {
      cookie = cookie.substring(1, cookie.length);
    }
    if (cookie.indexOf(cookieName) == 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return null;
}

export function eraseCookie(name: string) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}
