import { RouteConfig } from "vue-router";

const documentRoutes: RouteConfig[] = [
  {
    path: "",
    name: "documents",
    meta: {
      title: "meta.documents",
      authorize: [],
      tile: "documents",
    },
    component: () =>
      import(/* webpackChunkName: "documents" */ "@/components/documents/DocumentSearch.vue"),
  },
  {
    path: "upload",
    name: "uploadDocument",
    meta: {
      title: "meta.uploadDocument",
      authorize: [],
      tile: "documents",
    },
    component: () =>
      import(/* webpackChunkName: "documentUpload" */ "@/components/documents/DocumentUpload.vue"),
  },
  {
    path: ":id",
    name: "documentDetails",
    props: true,
    meta: {
      title: "meta.documentDetails",
      authorize: [],
      scrollToTop: true,
      tile: "documents",
    },
    component: () =>
      import(/* webpackChunkName: "documents" */ "@/components/documents/DocumentDetails.vue"),
  },
  {
    path: ":id/edit",
    name: "editDocument",
    meta: {
      title: "meta.editDocument",
      authorize: [],
      scrollToTop: true,
      tile: "documents",
    },
    component: () =>
      import(/* webpackChunkName: "documentUpload" */ "@/components/documents/DocumentUpload.vue"),
  },
  {
    path: ":id/new",
    name: "uploadNewDocument",
    meta: {
      title: "meta.uploadNewDocument",
      authorize: [],
      scrollToTop: true,
      tile: "documents",
    },
    component: () =>
      import(/* webpackChunkName: "documentUpload" */ "@/components/documents/DocumentUpload.vue"),
  },
];

export default documentRoutes;
