
import { nameCase } from "@foundernest/namecase";
import Avatar from "primevue/avatar";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import { TranslateResult } from "vue-i18n";
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import config from "@/config";
import { Assistant, IPerson, Representative, Role, UnauthorizedError } from "@/global";
import AccountModule, { resolveLocalIdentifier } from "@/store/modules/account";
import ConsentModule from "@/store/modules/consent";
import DocumentModule from "@/store/modules/document";
import { getShortName } from "@/utils";

@Component({
  components: {
    Button,
    Avatar,
    Dropdown,
  },
})
export default class ProxySelect extends Vue {
  private readonly accountState = getModule(AccountModule);
  private readonly documentState = getModule(DocumentModule);
  private readonly consentState = getModule(ConsentModule);

  isAssistant(person: any): person is Assistant {
    if (person.gln) return true;
    return false;
  }

  async selectProxy(person: any) {
    // person: Representative | Assistant
    let roleToSwitchTo = Role.None;
    let proxyPerson!: IPerson;
    if (this.isAssistant(person)) {
      const practitioner = person;
      this.accountState.setPractitionerGLN(practitioner.gln);
      proxyPerson = {
        name: practitioner.firstName + " " + practitioner.lastName,
      };
      roleToSwitchTo = Role.Assistant;
      this.accountState.setRole(roleToSwitchTo);

      this.accountState.setProxyPerson(proxyPerson);
      return;
    } else {
      const representative = person as Representative;
      this.accountState.setPatientSPID(representative.spid);
      proxyPerson = {
        name: `${representative.firstName} ${representative.lastName}`,
      };
      roleToSwitchTo = Role.Representative;
    }

    this.accountState.clearXUAToken();

    this.accountState.setProxyPerson(proxyPerson);

    this.accountState
      .getAuthenticationHeader()
      .then(async (xuaToken) => {
        const [tokenType, tokenValue] = xuaToken.split(" ");
        const localIdentifier = await resolveLocalIdentifier(
          tokenType,
          tokenValue,
          config.assigningAuthorities.local
        );

        this.accountState.setPatientLocalID(`${localIdentifier.system}|${localIdentifier.value}`);

        this.accountState.setRole(roleToSwitchTo);
        this.accountState.setProxyPerson(proxyPerson);
        this.documentState.clearDocuments();
        if (this.$route.name == "documents") {
          this.accountState.setNoAccessToThisEPD(false);
          this.documentState
            .fetchDocuments()
            .then(() => {
              this.accountState.setNoAccessToThisEPD(false);
              this.$notify({
                text: this.$t("toasts.documentStore.documentsFetchSuccess").toString(),
                type: "success",
                data: { srOnly: true },
              });
            })
            .catch((error) => {
              if (error instanceof UnauthorizedError) {
                this.accountState.setNoAccessToThisEPD(true);
              } else {
                this.$notify({
                  text: this.$t("toasts.documentStore.documentsFetchFail").toString(),
                  type: "error",
                  duration: -1,
                });

                this.accountState.setNoAccessToThisEPD(false);
              }
            });
        } else {
          this.$router.push({ name: "documents", params: { _reload: "true" } });
        }
      })
      .catch(() => {
        this.accountState.clearXUAToken();
        this.accountState.clearProxyPerson();
        this.$notify({
          text: this.$t("toasts.account.xuaFetchFail").toString(),
          type: "error",
          duration: -1,
        });
      });
  }

  async deselectProxy() {
    if (this.documentState.isLoading) {
      return;
    }

    this.documentState.clearDocuments();
    this.consentState.clearConsent();
    switch (this.accountState.role) {
      case Role.Patient:
      case Role.Representative:
        // Fall back to IDP Token? Or use the XUA token for the patient?
        this.accountState.clearXUAToken();
        this.accountState.clearPatientSPID();
        this.accountState.clearPatientLocalID();
        this.accountState.clearProxyPerson();
        this.accountState.setRole(Role.Patient);
        if (this.$route.name == "documents") {
          this.accountState.setNoAccessToThisEPD(false);
          this.documentState
            .fetchDocuments()
            .then(() => {
              this.accountState.setNoAccessToThisEPD(false);
              this.$notify({
                text: this.$t("toasts.documentStore.documentsFetchSuccess").toString(),
                type: "success",
                data: { srOnly: true },
              });
            })
            .catch((error) => {
              if (error instanceof UnauthorizedError) {
                this.accountState.setNoAccessToThisEPD(true);
              } else {
                this.accountState.setNoAccessToThisEPD(false);

                this.$notify({
                  text: this.$t("toasts.documentStore.documentsFetchFail").toString(),
                  type: "error",
                  duration: -1,
                });
              }
            });
        } else {
          this.$router.push({ name: "documents", params: { _reload: "true" } });
        }
        break;
      case Role.HealthcareProfessional:
      case Role.Assistant:
        // Go back to patient Search? Or
        this.accountState.clearProxyPerson();
        this.accountState.clearXUAToken();
        this.accountState.setRole(Role.HealthcareProfessional);
        this.accountState.logOutPatient();
        if (this.$route.name != "patients") {
          this.$router.push({ name: "patients" });
        }
        break;
    }
    this.accountState.fetchProxies();
  }

  get showProxySelection(): boolean {
    switch (this.accountState.role) {
      case Role.HealthcareProfessional:
        if (this.accountState.selectedPatient) {
          return false;
        } else {
          return true;
        }
      case Role.Patient:
        return true;
    }
    return false;
  }

  handleChange(event: { originalEvent: Event; value: Representative | Assistant }) {
    if (event.value) {
      this.selectProxy(event.value);
    } else {
      this.deselectProxy();
    }
  }

  handleShow() {
    if (this.accountState.proxyPerson) {
      this.deselectProxy();
    }
  }

  handleHide() {
    (document.activeElement as HTMLElement | null)?.blur();
  }

  get startText(): TranslateResult {
    switch (this.accountState.role) {
      case Role.Patient:
      case Role.Representative:
        return this.$t("navigation.proxies.startRepresentation");
      case Role.HealthcareProfessional:
      case Role.Assistant:
        return this.$t("navigation.proxies.startAssistance");
      default:
        return "";
    }
  }

  get stopText(): TranslateResult {
    switch (this.accountState.role) {
      case Role.HealthcareProfessional:
      case Role.Assistant:
        return this.$t("navigation.proxies.stopAssistance");
      case Role.Patient:
      case Role.Representative:
        return this.$t("navigation.proxies.stopRepresentation");
      default:
        return "";
    }
  }

  get emptyText(): TranslateResult {
    switch (this.accountState.role) {
      case Role.Patient:
      case Role.Representative:
        return this.$t("navigation.proxies.noRepresentatives");
      case Role.HealthcareProfessional:
      case Role.Assistant:
        return this.$t("navigation.proxies.noAssistants");
      default:
        return this.$t("navigation.proxies.noRepresentatives");
    }
  }

  get activeLabel(): TranslateResult {
    switch (this.accountState.role) {
      case Role.HealthcareProfessional:
      case Role.Assistant:
        return this.$t("navigation.proxies.assisting");
      case Role.Patient:
      case Role.Representative:
        return this.$t("navigation.proxies.representing");
      default:
        return "";
    }
  }

  get fullName(): string | null {
    const person = this.accountState.proxyPerson as any;
    if (!person) return null;

    if (Object.hasOwn(person, "firstName") && Object.hasOwn(person, "lastName")) {
      return `${person.firstName} ${person.lastName}`;
    }

    if (Object.hasOwn(person, "name")) {
      return person.name;
    }

    return null;
  }

  get shortName(): string | null {
    return this.fullName ? getShortName(this.fullName, 30) : null;
  }

  get name(): string | null {
    if (!this.accountState.proxyPerson) return null;

    return this.shortName ? nameCase(this.shortName, { lazy: false }) : null;
  }

  getName(person: any): string {
    if (!person) {
      return "";
    }
    const fullName = person.name || `${person.firstName} ${person.lastName}`;
    const shortName = getShortName(fullName, 30);
    return nameCase(shortName, { lazy: false });
  }
}
