
import { Component, Vue } from "vue-property-decorator";

import config from "@/config";

@Component
export default class Footer extends Vue {
  private showBuildNumber: boolean = false;

  get version(): string | null {
    return config?.debug?.versionTag || null;
  }

  get buildNumber(): string | null {
    return config?.debug?.commitHash || null;
  }

  get versionString(): string | null {
    if (this.version && this.buildNumber && this.showBuildNumber) {
      return this.buildNumber;
    } else if (this.version) {
      return this.version;
    } else if (this.buildNumber) {
      return this.buildNumber;
    } else {
      return "latest";
    }
  }
}
