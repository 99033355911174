import { R4 } from "@ahryman40k/ts-fhir-types";
import { TokenResponse } from "@openid/appauth";
import { Mutex } from "async-mutex";
import dayjs from "dayjs";
import Vue from "vue";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import config from "@/config";
import {
  Assistant,
  IDToken,
  IPatient,
  IPerson,
  IdentityProvider,
  JWT,
  Representative,
  Role,
  UnauthorizedError,
} from "@/global";
import { base64UrlDecodeUnicode } from "@/utils";

import AppAuth from "../../appauth";
import Store from "../index";

export enum LocalStorageItemKeys {
  AccessToken = "phellow:oauth2:accessToken",
  TokenType = "phellow:oauth2:accessToken:type",
  ExpiresAt = "phellow:oauth2:accessToken:expiresAt",
  RefreshToken = "phellow:oauth2:refreshToken",
  IDToken = "phellow:oauth2:idToken",
  RedirectPath = "phellow:router:redirect",
  SelectedPatient = "phellow:state:selectedPatient",
  SelectedPatientSPID = "phellow:state:spid",
  SelectedPatientLocalID = "phellow:state:localId",
  EmergencyAccess = "phellow:state:emergencyAccess",
  Role = "phellow:state:role",
  PractitionerGLN = "phellow:state:proxy:gln",
  ProxyPerson = "phellow:state:proxy:person",
  DocumentCount = "phellow:document:count",
  SelectedIdP = "phellow:state:selectedIdP",
}

export async function resolveLocalIdentifier(
  accessTokenType: string,
  accessToken: string,
  targetSystem: string
): Promise<{ value: string; system: string }> {
  const url = config.r4.patients + "?_format=json&_elements=identifier&identifier=me_";

  const response = await window.fetch(url, {
    method: "GET",
    headers: {
      Authorization: accessTokenType + " " + accessToken,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to resolve local ID to SPID.");
  }

  const body = (await response.json()) as R4.IBundle;
  const patients = (body.entry ?? []).map((entry) => entry.resource as R4.IPatient);

  if (patients.length <= 0) {
    throw new Error("Failed to resolve local ID to SPID.");
  }
  const identifier = patients[0].identifier?.filter(
    (identifier) => identifier?.system == targetSystem
  );

  if (!identifier || identifier.length < 1) {
    throw new Error("Failed to resolve local ID to SPID.");
  }

  return { value: identifier[0].value!!, system: identifier[0].system!! };
}

@Module({
  dynamic: true,
  store: Store,
  name: "account",
  namespaced: true,
})
export default class AccountModule extends VuexModule {
  accessToken: string | null = window.localStorage.getItem(LocalStorageItemKeys.AccessToken);
  tokenType: string | null = window.localStorage.getItem(LocalStorageItemKeys.TokenType);
  expiresAt: string | null = window.localStorage.getItem(LocalStorageItemKeys.ExpiresAt);
  refreshToken: string | null = window.localStorage.getItem(LocalStorageItemKeys.RefreshToken);
  idToken: string | null = window.localStorage.getItem(LocalStorageItemKeys.IDToken);
  redirectPath: string | null = window.sessionStorage.getItem(LocalStorageItemKeys.RedirectPath);
  selectedIdP: IdentityProvider = window.localStorage.getItem(
    LocalStorageItemKeys.SelectedIdP
  ) as IdentityProvider;

  proxies: (Representative | Assistant)[] | null = null;
  proxiesAreLoading: boolean = false;

  representatives: R4.IRelatedPerson[] | null = null;

  idleTimeout: dayjs.Dayjs | null = null;

  mutex: Mutex = new Mutex();

  /** The SPID of the currently logged in user (if the user is a patient and has an EPD)  */
  userSPID: string | null = null;

  sessionPoller: NodeJS.Timeout | null = null;

  xuaTokenType: string | null = null;
  xuaToken: string | null = null;
  xuaTokenExpiresAt: string | null = null;

  /** The person one wants access to. Set to null, when in the Patient role on ones own record. */
  selectedPatient: IPatient | null = this.loadSelectedPatient();
  /** The spid of the selected patient  */
  patientSPID: string | null = window.localStorage.getItem(
    LocalStorageItemKeys.SelectedPatientSPID
  );
  /** The local id of the selected patient  */
  patientLocalID: string | null = window.localStorage.getItem(
    LocalStorageItemKeys.SelectedPatientSPID
  );
  /** The GLN of the person one wants to be assistant to. Only relevant when HCP wants ASS access. */
  practitionerGLN: string | null = window.localStorage.getItem(
    LocalStorageItemKeys.PractitionerGLN
  );
  /** Keep track if HCP is in emergency access mode */
  hasEmergencyAccess: boolean = this.loadEmergencyAccess();
  emergencyAccessRequested: boolean = false;

  // The selected proxy person. Either Assistant or Representative
  proxyPerson: IPerson | null = this.loadSelectedProxyPerson();

  // The role the currently logged in user has
  role: Role =
    (window.localStorage.getItem(LocalStorageItemKeys.Role) as Role) || config.role || Role.None;

  loginIsLoading: boolean = false;

  captchaToken: string | null = null;

  // IF a user (especially a HCP) has no access to the selected EPD
  noAccessToThisEPD: boolean = false;

  loadSelectedPatient(): IPatient | null {
    const selectedPatientString = window.localStorage.getItem(LocalStorageItemKeys.SelectedPatient);
    if (selectedPatientString) {
      const parsedPatient = JSON.parse(selectedPatientString) as IPatient | undefined;
      if (parsedPatient) {
        if (parsedPatient.dateOfBirth) {
          parsedPatient.dateOfBirth = dayjs(parsedPatient.dateOfBirth);
          return parsedPatient;
        }
      }
      return null;
    }
    return null;
  }

  loadEmergencyAccess(): boolean {
    const loadedAccess = window.localStorage.getItem(LocalStorageItemKeys.EmergencyAccess);
    if (loadedAccess) {
      return JSON.parse(loadedAccess) === true;
    }
    return false;
  }

  loadSelectedProxyPerson(): IPerson | null {
    const selectedProxyString = window.localStorage.getItem(LocalStorageItemKeys.ProxyPerson);
    if (selectedProxyString) {
      const parsedProxy = JSON.parse(selectedProxyString) as IPerson | undefined;
      return parsedProxy || null;
    }
    return null;
  }

  @Action({ rawError: true })
  async getAuthenticationHeader(endpointNeedsIDP: boolean = false): Promise<string> {
    return this.mutex.runExclusive(async () => {
      return this.context.dispatch("_getAuthenticationHeader", endpointNeedsIDP) as Promise<string>;
    });
  }

  @Action({ rawError: true })
  async _getAuthenticationHeader(endpointNeedsIDP: boolean = false): Promise<string> {
    let headers = new Headers();

    // We always need a valid ID Token
    if (this.tokenType && this.accessToken && this.expiresAt) {
      if (this.refreshToken) {
        const tokenResponse = await AppAuth(this.selectedIdP!).refresh(this.refreshToken);
        this.setIDPToken(tokenResponse);
      }
    } else {
      throw new Error("One or more IDP token fields are null.");
    }

    if (endpointNeedsIDP) {
      // IDP Token required
      return this.tokenType + " " + this.accessToken;
    } else {
      // Default to XUA Token
      if (this.xuaToken && this.xuaTokenExpiresAt && !this.emergencyAccessRequested) {
        const now = dayjs().add(5, "second");
        const expiration = dayjs(this.xuaTokenExpiresAt);

        if (now.isBefore(expiration)) {
          return this.xuaTokenType + " " + this.xuaToken;
        } else {
          // Fetch new XUA token below
        }
      } else {
        // Fetch new XUA token below
      }

      const url = config.openid[this.selectedIdP]!!.tokenEndpoint;
      const urlParams = new URLSearchParams();
      urlParams.append("client_id", config.openid[this.selectedIdP]!!.clientId);
      urlParams.append("grant_type", "urn:ietf:params:oauth:grant-type:token-exchange");
      urlParams.append("subject_token", this.accessToken!);
      urlParams.append("subject_token_type", "urn:ietf:params:oauth:token-type:access_token");
      urlParams.append("home_community_id", "urn:oid:" + config.homeCommunity.id);

      // Fetch new XUA token here
      // Build URL according to flow chart
      switch (config.role) {
        case Role.HealthcareProfessional:
          // SPID necessary for all
          if (this.patientSPID) {
            urlParams.append("resource_id", this.patientSPID!);
          } else {
            throw new Error("No SPID defined.");
          }

          if (this.practitionerGLN && this.proxyPerson) {
            // HCP wants access to PAT as ASS
            urlParams.append("role", Role.Assistant);
            urlParams.append("principal_id", this.practitionerGLN);
            urlParams.append("principal_name", this.proxyPerson.name);
          } else {
            // HCP wants access to PAT
            urlParams.append("role", Role.HealthcareProfessional);
          }

          // Emergency Access
          if (this.emergencyAccessRequested || this.hasEmergencyAccess) {
            urlParams.append("purpose_of_use", "EMER");

            if (this.captchaToken) {
              headers = new Headers([["Captcha-Token", this.captchaToken]]);
            }
          } else {
            urlParams.append("purpose_of_use", "NORM");
          }

          break;
        case Role.Patient:
          urlParams.append("purpose_of_use", "NORM");
          if (this.patientSPID) {
            // PAT wants REP Access
            urlParams.append("role", Role.Representative);
            urlParams.append("resource_id", this.patientSPID!);
          } else {
            // PAT wants their own XUA token
            urlParams.append("role", Role.Patient);

            if (!this.userSPID) {
              if (config.debug.staticSPID && this.parsedIDToken?.sub) {
                const mappedSPID = JSON.parse(config.debug.staticSPID);
                const staticSPID = mappedSPID[this.parsedIDToken.sub];
                this.setUserSPID(staticSPID);
              } else {
                this.setUserSPID(
                  (
                    await resolveLocalIdentifier(
                      this.tokenType!,
                      this.accessToken!,
                      config.assigningAuthorities.spid
                    )
                  ).value
                );
              }
            }

            urlParams.append("resource_id", this.userSPID!);
          }
          break;
        default:
          throw new Error("Wrong role in configuration: " + config.role);
      }

      // Start API call
      const encodedURL = encodeURI(url);
      const response = await window.fetch(encodedURL, {
        method: "POST",
        body: urlParams,
        headers,
      });

      if (!response.ok) {
        if (response.status == 400 && config.role == Role.HealthcareProfessional) {
          throw new UnauthorizedError("toasts.auth.unauthorizedPatientAccess");
        } else {
          throw new Error("XUA Token Response error");
        }
      }

      const tokenResponse = (await response.json()) as any;

      const expirationDate: string = dayjs().add(tokenResponse.expires_in, "second").format();

      this.setXUATokenExpiresAt(expirationDate);
      this.setXUAToken(tokenResponse.access_token);
      this.setXUATokenType(tokenResponse.token_type);

      return this.xuaTokenType + " " + this.xuaToken;
    }
  }

  get parsedIDToken(): IDToken | null {
    if (this.idToken) {
      const parts = this.idToken.split(".");
      if (parts.length === 3) {
        const decoded = base64UrlDecodeUnicode(parts[1]);
        return JSON.parse(decoded) as IDToken;
      }
    }

    return null;
  }

  get loggedInUserRoles(): string[] {
    if (JSON.parse(config.debug.disableRoleCheck) === true) {
      return [Role.Patient, Role.Representative, Role.HealthcareProfessional, Role.Assistant];
    }

    return this.parsedIDToken?.roles || [];
  }

  @Action
  async canReuseSession(): Promise<boolean> {
    if (this.accessToken && this.tokenType && this.idToken && this.expiresAt) {
      const response = await fetch(config.openid[this.selectedIdP]!.sessionEndpoint, {
        method: "GET",
        credentials: "include",
      });

      return response.status == 200;
    }

    return false;
  }

  @Mutation
  setRedirectPath(path: string) {
    window.sessionStorage.setItem(LocalStorageItemKeys.RedirectPath, path);
    this.redirectPath = path;
  }

  @Mutation
  clearRedirectPath() {
    window.sessionStorage.removeItem(LocalStorageItemKeys.RedirectPath);
    this.redirectPath = null;
  }

  @Mutation
  setLoading(loading: boolean) {
    this.loginIsLoading = loading;
  }

  @Mutation
  setIDPToken(tokenResponse: TokenResponse) {
    const expirationDate: string = dayjs().add(tokenResponse.expiresIn!, "second").format();

    window.localStorage.setItem(LocalStorageItemKeys.AccessToken, tokenResponse.accessToken);
    window.localStorage.setItem(LocalStorageItemKeys.TokenType, tokenResponse.tokenType);
    window.localStorage.setItem(LocalStorageItemKeys.ExpiresAt, expirationDate);
    tokenResponse.refreshToken &&
      window.localStorage.setItem(LocalStorageItemKeys.RefreshToken, tokenResponse.refreshToken);

    if (tokenResponse.idToken) {
      window.localStorage.setItem(LocalStorageItemKeys.IDToken, tokenResponse.idToken);
      this.idToken = tokenResponse.idToken;
    }

    this.accessToken = tokenResponse.accessToken;
    this.tokenType = tokenResponse.tokenType;
    this.expiresAt = expirationDate;
    this.refreshToken = tokenResponse.refreshToken || null;
  }

  @Mutation
  setProxies(proxies: (Representative | Assistant)[] | null) {
    this.proxies = proxies;
  }

  @Mutation
  setProxiesLoading(loading: boolean) {
    this.proxiesAreLoading = loading;
  }

  @Action
  async updateSessionTimeout() {
    const response = await fetch(config.openid[this.selectedIdP]!!.sessionEndpoint, {
      method: "GET",
      credentials: "include",
    });

    if (response.status == 401) {
      window.dispatchEvent(new CustomEvent("phellow:event:logout"));
      return;
    }

    const body = await response.json();
    this.resetIdleTimeout(dayjs(body.expiresAt));
  }

  @Action
  startSessionPoller() {
    if (!this.sessionPoller)
      this.setSessionPoller(
        setInterval(async () => {
          await this.updateSessionTimeout();
        }, 5000)
      );
  }

  @Action({ rawError: true })
  async fetchRepresentatives() {
    const authHeader = await this.getAuthenticationHeader(true);
    if (authHeader == null) {
      return;
    }

    const response = await fetch(encodeURI(config.eprServices.relatedPerson), {
      method: "GET",
      headers: {
        Authorization: authHeader,
      },
    });

    this.setRepresentatives(await response.json());
  }

  @Action({ rawError: true })
  async fetchProxies(options?: { autoRepresent: boolean }) {
    this.setProxiesLoading(true);
    let url = "";
    let failureMessage = "";
    switch (this.role) {
      case Role.Patient:
      case Role.Representative:
        url = config.eprServices.representedPerson;
        failureMessage = "navigation.proxies.fetchRepresentativesFailed";
        break;
      case Role.HealthcareProfessional:
      case Role.Assistant:
        url = config.eprServices.assistedPerson;
        failureMessage = "navigation.proxies.fetchAssistantsFailed";
        break;
      default:
        this.setProxies(null);
        this.setProxiesLoading(false);
        return;
    }

    const authHeader = await this.getAuthenticationHeader(true);
    if (authHeader == null) {
      this.setProxiesLoading(false);
      return;
    }

    const response = await window.fetch(encodeURI(url), {
      method: "GET",
      headers: {
        Authorization: authHeader,
        "Content-Type": "application/json; charset=utf-8",
      },
    });

    if (response.ok) {
      const fetchedProxies = (await response.json()) as (Representative | Assistant)[];

      const roles = this.parsedIDToken?.roles;

      // set default proxy if Assistant of only one HealthcareProfessional
      if (
        options?.autoRepresent &&
        roles?.length === 1 &&
        roles[0] === Role.Assistant &&
        fetchedProxies.length === 1
      ) {
        const practitioner: Assistant = fetchedProxies[0] as Assistant;
        this.setPractitionerGLN(practitioner.gln);
        const proxyPerson: IPerson = {
          name: practitioner.firstName + " " + practitioner.lastName,
        };
        this.setRole(Role.Assistant);

        this.setProxyPerson(proxyPerson);
      }

      this.setProxies(fetchedProxies);
      this.setProxiesLoading(false);
    } else {
      this.setProxies(null);
      this.setProxiesLoading(false);
      return failureMessage;
    }
  }

  @Action({ rawError: true })
  async refresh() {
    if (this.refreshToken) {
      const tokenResponse = await AppAuth(this.selectedIdP!).refresh(this.refreshToken);
      this.setIDPToken(tokenResponse);
    }
  }

  @Action({ rawError: true })
  logOutPatient() {
    this.clearXUAToken();
    this.clearPatientSPID();
    this.clearPatientLocalID();
    this.clearSelectedPatient();
    this.setEmergencyAccessRequested(false);
    this.setHasEmergencyAccess(false);
    this.context.commit("hcprovider/clearSessionHCPs", null, { root: true });
    this.context.commit("document/clearDocuments", null, { root: true });
    this.context.commit("consent/clearConsent", null, { root: true });
  }

  @Mutation
  setRepresentatives(relatedPersonList: R4.IList) {
    this.representatives =
      relatedPersonList.entry!.map((entry) => {
        return relatedPersonList.contained!.find(
          (relatedPerson) => relatedPerson.id === entry.item.reference
        ) as R4.IRelatedPerson;
      }) || [];
  }

  @Mutation
  setSessionPoller(timeout: NodeJS.Timeout) {
    this.sessionPoller = timeout;
  }

  @Mutation
  clearIDPToken() {
    window.localStorage.removeItem(LocalStorageItemKeys.AccessToken);
    window.localStorage.removeItem(LocalStorageItemKeys.TokenType);
    window.localStorage.removeItem(LocalStorageItemKeys.ExpiresAt);
    window.localStorage.removeItem(LocalStorageItemKeys.RefreshToken);

    this.accessToken = null;
    this.tokenType = null;
    this.expiresAt = null;
    this.refreshToken = null;
  }

  @Mutation
  resetIdleTimeout(time: dayjs.Dayjs) {
    this.idleTimeout = time;
  }

  @Mutation
  setIdleTimeout(timeout: dayjs.Dayjs) {
    this.idleTimeout = timeout;
  }

  @Mutation
  setUserSPID(spid: string | null) {
    this.userSPID = spid;
  }

  @Mutation
  clearUserSPID() {
    this.userSPID = null;
  }

  @Mutation
  clearXUAToken() {
    this.xuaToken = null;
    this.xuaTokenExpiresAt = null;
  }

  @Mutation
  setXUAToken(token: string | null) {
    this.xuaToken = token;
  }

  @Mutation
  setXUATokenType(token: string | null) {
    this.xuaTokenType = token;
  }

  @Mutation
  setXUATokenExpiresAt(expiresAt: string | null) {
    this.xuaTokenExpiresAt = expiresAt; // format example: 2020-02-19T12:23:30.67Z
  }

  @Mutation
  setEmergencyAccessRequested(requested: boolean) {
    this.emergencyAccessRequested = requested;
  }

  @Mutation
  setHasEmergencyAccess(bool: boolean) {
    this.hasEmergencyAccess = bool;
    window.localStorage.setItem(LocalStorageItemKeys.EmergencyAccess, JSON.stringify(bool));
  }

  @Mutation
  setRole(role: Role) {
    this.role = role;
    window.localStorage.setItem(LocalStorageItemKeys.Role, role);
  }

  @Mutation
  setSelectedPatient(patient: IPatient) {
    this.selectedPatient = patient;
    window.localStorage.setItem(LocalStorageItemKeys.SelectedPatient, JSON.stringify(patient));
  }

  @Mutation
  clearSelectedPatient() {
    this.selectedPatient = null;
    window.localStorage.removeItem(LocalStorageItemKeys.SelectedPatient);
  }

  @Mutation
  setPatientSPID(spid: string) {
    this.patientSPID = spid;
    window.localStorage.setItem(LocalStorageItemKeys.SelectedPatientSPID, spid);
  }

  @Mutation
  clearPatientSPID() {
    this.patientSPID = null;
    window.localStorage.removeItem(LocalStorageItemKeys.SelectedPatientSPID);
  }

  @Mutation
  setPatientLocalID(localId: string) {
    this.patientLocalID = localId;
    window.localStorage.setItem(LocalStorageItemKeys.SelectedPatientLocalID, localId);
  }

  @Mutation
  clearPatientLocalID() {
    this.patientLocalID = null;
    window.localStorage.removeItem(LocalStorageItemKeys.SelectedPatientLocalID);
  }

  @Mutation
  setProxyPerson(person: IPerson) {
    this.proxyPerson = person;
    window.localStorage.setItem(LocalStorageItemKeys.ProxyPerson, JSON.stringify(person));
  }

  @Mutation
  clearProxyPerson() {
    this.proxyPerson = null;
    window.localStorage.removeItem(LocalStorageItemKeys.ProxyPerson);
  }

  @Mutation
  setPractitionerGLN(gln: string) {
    this.practitionerGLN = gln;
    window.localStorage.setItem(LocalStorageItemKeys.PractitionerGLN, gln);
  }

  @Mutation
  clearPractitionerGLN() {
    this.practitionerGLN = null;
    window.localStorage.removeItem(LocalStorageItemKeys.PractitionerGLN);
  }

  @Mutation
  setCaptchaToken(token: string) {
    this.captchaToken = token;
  }

  @Mutation
  clearCaptchaToken() {
    this.captchaToken = null;
  }

  @Mutation
  setNoAccessToThisEPD(noAccess: boolean) {
    this.noAccessToThisEPD = noAccess;
  }

  @Mutation
  clearNoAccessToThisEPD() {
    this.noAccessToThisEPD = false;
  }

  @Action({ rawError: true })
  startAuthFlow(provider: IdentityProvider) {
    window.localStorage.setItem(LocalStorageItemKeys.SelectedIdP, provider);
    AppAuth(provider).authorizationRequest();
  }

  @Action({ rawError: true })
  async completeIfPossible() {
    // this.setLoading(true);
    return AppAuth(this.selectedIdP!)
      .completeIfPossible()
      .then((response) => {
        this.setIDPToken(response);
      });
  }

  @Mutation
  logout() {
    window.localStorage.removeItem(LocalStorageItemKeys.AccessToken);
    window.localStorage.removeItem(LocalStorageItemKeys.TokenType);
    window.localStorage.removeItem(LocalStorageItemKeys.ExpiresAt);
    window.localStorage.removeItem(LocalStorageItemKeys.RefreshToken);
    window.localStorage.removeItem(LocalStorageItemKeys.IDToken);
    window.localStorage.removeItem(LocalStorageItemKeys.SelectedPatient);
    window.localStorage.removeItem(LocalStorageItemKeys.SelectedPatientSPID);
    window.localStorage.removeItem(LocalStorageItemKeys.SelectedPatientLocalID);
    window.localStorage.removeItem(LocalStorageItemKeys.ProxyPerson);
    window.localStorage.removeItem(LocalStorageItemKeys.PractitionerGLN);
    window.localStorage.removeItem(LocalStorageItemKeys.Role);
    window.localStorage.removeItem(LocalStorageItemKeys.EmergencyAccess);
    window.localStorage.removeItem(LocalStorageItemKeys.EmergencyAccess);
    window.localStorage.removeItem(LocalStorageItemKeys.RedirectPath);
    window.localStorage.removeItem(LocalStorageItemKeys.DocumentCount);

    this.userSPID = null;
    this.accessToken = null;
    this.tokenType = null;
    this.expiresAt = null;
    this.refreshToken = null;
    this.idToken = null;
    this.role = config.role;
    this.xuaTokenType = null;
    this.xuaToken = null;
    this.xuaTokenExpiresAt = null;
    this.selectedPatient = null;
    this.patientSPID = null;
    this.patientLocalID = null;
    this.proxyPerson = null;
    this.practitionerGLN = null;
    this.hasEmergencyAccess = false;

    if (this.sessionPoller) clearInterval(this.sessionPoller!);

    Vue.notify({ clean: true });
  }

  /**
   * checks whether the logged in user may access this portal.
   * User of role PAT musn't enter the Professional Portal for example.
   *
   * @readonly
   * @type {boolean}
   * @memberof AccountModule
   */
  public get idpTokenMatchesConfiguredRole(): boolean {
    const roles = this.loggedInUserRoles;
    if (config.role == Role.HealthcareProfessional) {
      return roles.includes(Role.HealthcareProfessional) || roles.includes(Role.Assistant);
    } else {
      return roles.includes(Role.Patient) || roles.includes(Role.Representative);
    }
  }

  public get hasValidToken(): boolean {
    if (this.accessToken == null || this.tokenType == null || this.expiresAt == null) {
      return false;
    }

    if (this.idleTimeout != null) dayjs(this.idleTimeout).isAfter(dayjs());

    return true;
  }

  get parsedXUAToken(): JWT | null {
    if (this.xuaToken) {
      const parts = this.xuaToken.split(".");
      if (parts.length === 3) {
        const decoded = base64UrlDecodeUnicode(parts[1]);
        return JSON.parse(decoded) as JWT;
      }
    }
    return null;
  }

  get loggedInUserGLN(): string | null {
    if (this.parsedXUAToken) {
      return this.parsedXUAToken.sub || null;
    }
    return null;
  }

  get currentUser(): IPerson | null {
    if (this.parsedIDToken?.name) {
      return {
        name: this.parsedIDToken.name,
      };
    } else if (this.parsedIDToken?.sub) {
      return {
        name: this.parsedIDToken.sub,
      };
    }
    return null;
  }
}
