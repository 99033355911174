import "./registerComponentHooks";
import "primeicons/primeicons.css";
import "./assets/styles/tailwind.pcss";
import "primevue/resources/primevue.min.css";
import "./assets/styles/primevue-theme.css";

import BadgeDirective from "primevue/badgedirective";
import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import Tooltip from "primevue/tooltip";
import Vue from "vue";
import Notifications from "vue-notification";
import Vuelidate from "vuelidate";

import App from "./App.vue";
import i18n from "./i18n";
import sessionMixin from "./mixins/sessionMixin";
import titleMixin from "./mixins/titleMixin";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

Vue.use(Notifications);
Vue.use(Vuelidate);
Vue.use(PrimeVue, {
  ripple: true,
  locale: (i18n.messages[i18n.locale] || i18n.messages["de-ch"]).global,
});
Vue.use(ConfirmationService);
Vue.mixin(titleMixin);
Vue.mixin(sessionMixin);
Vue.directive("badge", BadgeDirective);
Vue.directive("tooltip", Tooltip);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
