import { R4 } from "@ahryman40k/ts-fhir-types";
import { Dayjs } from "dayjs";
import Vue from "vue";
import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";

import config from "@/config";
import i18n from "@/i18n";
import {
  PolicySetIdReferenceValue_AccessLevel,
  PolicySetIdReferenceValue_ProvideLevel,
} from "@/store/modules/consent";
import {
  AuditEventType,
  AuditTrailConsumptionEventType,
  DocumentAuditEventDetailTypes,
  EprParticipant,
  EprPurposeOfUse,
  PolicyAuditEventDetailTypes,
} from "@/valueSets/ValueSetEnums";

import Store from "../index";
import AccountModule from "./account";

// import i18n from "@/i18n";

// interface DocumentAuditEvent {
//   id: string;
//   text: R4.INarrative;
//   type: R4.ICoding;
//   subtype: DocumentAuditEventType;
//   recorded: string;
//   purposeOfEvent: R4.ICodeableConcept[];
//   agent: ATCAgent[];
//   entity: R4.IAuditEvent_Entity;
// }

// interface ATCAgent {
//   role: R4.ICodeableConcept[]; // system: "2.16.756.5.30.1.127.3.10.6",
//   userId?: string;
//   name: string;
//   requestor: boolean;
// }

export class ATCAuditEvent {
  public auditEvent: R4.IAuditEvent;

  public constructor(auditEvent: R4.IAuditEvent) {
    this.auditEvent = auditEvent;
  }

  get auditEventType(): AuditEventType {
    switch (this.subtype) {
      case AuditTrailConsumptionEventType.DOC_CREATE:
      case AuditTrailConsumptionEventType.DOC_DELETE:
      case AuditTrailConsumptionEventType.DOC_READ:
      case AuditTrailConsumptionEventType.DOC_UPDATE:
      case AuditTrailConsumptionEventType.DOC_SEARCH:
        return AuditEventType.DocumentAuditEventType;
      case AuditTrailConsumptionEventType.POL_CREATE_AUT_PART_AL:
      case AuditTrailConsumptionEventType.POL_UPDATE_AUT_PART_AL:
      case AuditTrailConsumptionEventType.POL_REMOVE_AUT_PART_AL:
      case AuditTrailConsumptionEventType.POL_DEF_CONFLEVEL:
      case AuditTrailConsumptionEventType.POL_DIS_EMER_USE:
      case AuditTrailConsumptionEventType.POL_ENA_EMER_USE:
      case AuditTrailConsumptionEventType.POL_EXL_BLACKLIST:
      case AuditTrailConsumptionEventType.POL_INCL_BLACKLIST:
        return AuditEventType.PolicyAuditEventType;
      case AuditTrailConsumptionEventType.LOG_READ:
        return AuditEventType.AuditTrailEventType;
      case AuditTrailConsumptionEventType.HPD_GROUP_ENTRY_NOTIFIY:
        return AuditEventType.HpdGroupEntryEventType;
    }
  }

  get id(): string {
    return this.auditEvent.id!;
  }

  get text(): string {
    return this.auditEvent.text!.div;
  }

  get type(): string {
    return this.auditEvent.type.code!;
  }

  get subtype(): AuditTrailConsumptionEventType {
    return this.auditEvent.subtype![0].code! as AuditTrailConsumptionEventType;
  }

  get outcome(): R4.AuditEventOutcomeKind | undefined {
    return this.auditEvent.outcome;
  }

  get recorded(): Date {
    return new Date(this.auditEvent.recorded!);
  }

  get purposeOfEvent(): EprPurposeOfUse | undefined {
    if (
      this.auditEvent.purposeOfEvent &&
      this.auditEvent.purposeOfEvent![0].coding &&
      this.auditEvent.purposeOfEvent![0].coding![0].code
    ) {
      return this.auditEvent.purposeOfEvent![0].coding![0].code! as EprPurposeOfUse;
    }
    return undefined;
  }

  get wasEmergencyAccess(): boolean {
    if (this.purposeOfEvent) {
      return this.purposeOfEvent == EprPurposeOfUse.EMER;
    }
    return false;
  }

  get initiator(): R4.IAuditEvent_Agent | null {
    if (this.auditEvent.subtype?.[0].code == "ATC_HPD_GROUP_ENTRY_NOTIFY") {
      const name = this.hcpEntity?.name;
      if (name) {
        return { name };
      }
      return null;
    }
    return (this.auditEvent.agent || []).find((agent) => agent.requestor!)!;
  }

  get otherAgents(): R4.IAuditEvent_Agent[] {
    // TODO: Hardcoded system
    // urn:oid:2.16.756.5.30.1.127.3.10.6
    // urn:oid:2.16.756.5.30.1.127.3.10.8

    return this.auditEvent.agent.filter((agent) => {
      if (agent.role && agent.role![0].coding && agent.role![0].coding![0].system) {
        const system = agent.role![0].coding![0].system;
        const normalized = system.replace("urn:oid:", "");
        return (
          normalized == "2.16.756.5.30.1.127.3.10.8" || normalized == "2.16.756.5.30.1.127.3.10.6"
        );
      } else {
        return false;
      }
    });
  }

  static role(forOtherAgent: R4.IAuditEvent_Agent | null): EprParticipant | null {
    return forOtherAgent?.role?.[0].coding?.[0].code as EprParticipant;
  }

  get patientEntity(): R4.IAuditEvent_Entity {
    return this.auditEvent.entity!.find((entity) => entity.type!.code! == "1")!;
  }

  ////////////////////////
  // DocumentAuditEvent //
  ////////////////////////

  get documentEntity(): R4.IAuditEvent_Entity | undefined {
    return this.auditEvent.entity!.find((entity) => {
      return entity.type!.code == "2" && entity.role!.code == "3";
    });
  }

  get documentEntityIdentifier(): string | undefined {
    if (this.documentEntity) {
      const identifier = this.documentEntity.what?.identifier;
      if (identifier) {
        const value = identifier.value!;
        if (value.includes("^")) {
          return value.split("^")[1];
        } else if (value.startsWith("urn:uuid:")) {
          return value.replace("urn:uuid:", "");
        } else if (value.startsWith("urn:oid:")) {
          return value.replace("urn:oid:", "");
        } else {
          return value;
        }
      }
    }

    return undefined;
  }

  get documentEntityHomeCommunityID(): string | undefined {
    return (
      this.documentDetails(DocumentAuditEventDetailTypes.HOME_COMMUNITY_ID) ||
      config.homeCommunity.id
    );
  }

  get documentEntityRepositoryUniqueID(): string | undefined {
    return this.documentDetails(DocumentAuditEventDetailTypes.REPOSITORY_UNIQUE_ID);
  }

  get documentEntityEprDocumentTypeCode(): string | undefined {
    return this.documentDetails(DocumentAuditEventDetailTypes.EPR_DOCUMENT_TYPE_CODE);
  }

  get documentTitle(): string | undefined {
    return this.documentDetails(DocumentAuditEventDetailTypes.TITLE);
  }

  private documentDetails(forType: DocumentAuditEventDetailTypes): string | undefined {
    if (this.documentEntity) {
      const encoded = this.documentEntity.detail!.find(
        (detail) => detail.type! == forType
      )?.valueBase64Binary;
      if (encoded) {
        return atob(encoded);
      }
    }
    return undefined;
  }

  //////////////////////
  // PolicyAuditEvent //
  //////////////////////

  get resourceEntity(): R4.IAuditEvent_Entity | undefined {
    return this.auditEvent.entity!.find((entity) => {
      return entity.type!.code == "2";
    });
  }

  get resourceEntityRole(): EprParticipant | undefined {
    if (this.resourceEntity) {
      return this.resourceEntity.role?.code as EprParticipant;
    }

    return undefined;
  }

  get resourceEntityName(): string | undefined {
    if (this.resourceEntity && this.resourceEntity.name && this.resourceEntity.name !== "") {
      return this.resourceEntity.name;
    } else if (
      this.resourceEntity &&
      this.resourceEntity.what &&
      this.resourceEntity.what.identifier
    ) {
      return `[${this.resourceEntity.what.identifier.system}|${this.resourceEntity.what.identifier.value}]`;
    }

    return undefined;
  }

  // If subtype == (POL_CREATE_AUT_PART_AL || POL_UPDATE_AUT_PART_AL)
  get resourceEntityAccessLevel(): PolicySetIdReferenceValue_AccessLevel | undefined {
    const accessLevel = this.resourceDetails(PolicyAuditEventDetailTypes.ACCESS_LEVEL);
    if (accessLevel) return accessLevel as PolicySetIdReferenceValue_AccessLevel;

    return undefined;
  }

  // If subtype == (POL_CREATE_AUT_PART_AL || POL_UPDATE_AUT_PART_AL)
  get resourceEntityAccessLimitedToDate(): Date | undefined {
    const limitedToDate = this.resourceDetails(PolicyAuditEventDetailTypes.ACCESS_LIMITED_TO_DATE);
    if (limitedToDate) {
      return new Date(limitedToDate);
    }
    return undefined;
  }

  // If subtype == ATC_POL_DEF_CONFLEVEL
  get resourceEntityProvideLevel(): PolicySetIdReferenceValue_ProvideLevel | undefined {
    const provideLevel = this.resourceDetails(PolicyAuditEventDetailTypes.PROVIDE_LEVEL);
    if (provideLevel) return provideLevel as PolicySetIdReferenceValue_ProvideLevel;

    return undefined;
  }

  private resourceDetails(forType: PolicyAuditEventDetailTypes): string | undefined {
    if (this.resourceEntity) {
      const encoded = this.resourceEntity.detail!.find(
        (detail) => detail.type! == forType
      )?.valueBase64Binary;
      if (encoded) {
        return atob(encoded);
      }
    }
    return undefined;
  }

  //////////////////////
  // HCP Group Entry //
  //////////////////////

  get groupEntity(): R4.IAuditEvent_Entity | undefined {
    return this.auditEvent.entity!.find((entity) => {
      return entity.type?.code == "3" && entity.role?.code == "GRP";
    });
  }

  get groupName(): string | undefined {
    return this.groupEntity?.name;
  }

  get hcpEntity(): R4.IAuditEvent_Entity | undefined {
    return this.auditEvent.entity!.find((entity) => {
      return entity.type?.code == "1" && entity.role?.code == "HCP";
    });
  }

  get hcpName(): string | undefined {
    return this.hcpEntity?.name;
  }

  /////////////////
  // Convenience //
  /////////////////

  get isDocumentAuditEvent(): boolean {
    return this.auditEventType == AuditEventType.DocumentAuditEventType;
  }

  get isPolicyAuditEvent(): boolean {
    return this.auditEventType == AuditEventType.PolicyAuditEventType;
  }

  get isAuditTrailEvent(): boolean {
    return this.auditEventType == AuditEventType.AuditTrailEventType;
  }
}

const accountState = getModule(AccountModule);
const authHeaderFunction = async function (): Promise<string | null> {
  try {
    return accountState.getAuthenticationHeader();
  } catch (error) {
    Vue.notify({
      text: i18n.t("toasts.auth.tokenFetchFail").toString(),
      type: "error",
      duration: -1,
    });
    return null;
  }
};

export interface AuditEventQueryParameter {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  subtype: AuditTrailConsumptionEventType | null;
  role: EprParticipant | null;
}

////////////////
// Vuex Store //
////////////////

@Module({
  dynamic: true,
  store: Store,
  name: "auditEvents",
  namespaced: true,
})
export default class AuditEventModule extends VuexModule {
  public auditEvents: ATCAuditEvent[] | null = null;
  public isLoading: boolean = false;

  @Mutation
  clearAuditEvents() {
    this.auditEvents = null;
  }

  @Mutation
  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  setAuditEvents(auditEvents: ATCAuditEvent[] | null) {
    this.auditEvents = auditEvents;
  }

  @Action({ rawError: true })
  async fetchAuditEvents(queryParameter: AuditEventQueryParameter) {
    this.setLoading(true);

    let url = config.r4.auditEvent;
    const urlParams: string[] = [];

    // TODO: dynamic via parameters
    if (queryParameter.startDate) {
      urlParams.push(`date=ge${queryParameter.startDate.format("YYYY-MM-DD")}`);
    }
    if (queryParameter.endDate) {
      urlParams.push(`date=le${queryParameter.endDate.format("YYYY-MM-DD")}`);
    }
    if (queryParameter.subtype) {
      urlParams.push(`subtype=${queryParameter.subtype}`);
    }
    if (queryParameter.role) {
      urlParams.push(`entity-role=${queryParameter.role}`);
    }

    if (urlParams.length > 0) {
      url += "?" + urlParams.join("&");
    }

    const authHeader = await authHeaderFunction();
    if (authHeader == null) {
      this.setAuditEvents(null);
      this.setLoading(false);
      throw new Error("toasts.auth.authHeaderFail");
    }

    const response = await window.fetch(encodeURI(url), {
      method: "GET",
      headers: {
        Authorization: authHeader,
      },
    });

    if (!response.ok) {
      this.setAuditEvents(null);
      this.setLoading(false);
      throw new Error("toasts.atc.fetchFail");
    }

    const bundle = (await response.json()) as R4.IBundle;

    if (bundle.total && bundle.total > 0 && bundle.entry) {
      const fetchedAuditEvents = bundle.entry
        .filter((entry) => entry?.resource?.resourceType == "AuditEvent")
        .map((entry) => new ATCAuditEvent(entry.resource as R4.IAuditEvent))
        .reverse();

      this.setAuditEvents(fetchedAuditEvents);
      this.setLoading(false);
      return;
    }

    this.setAuditEvents([]);
    this.setLoading(false);
  }
}
