import { RouteConfig } from "vue-router";

const onboardingRoutes: RouteConfig[] = [
  {
    path: "",
    name: "register",
    meta: {
      title: "meta.register",
      authorize: [],
    },
    component: () => import("@/components/patientOnboarding/PatientOnboardingForm.vue"),
  },
  {
    path: "info",
    name: "registerInfo",
    meta: {
      title: "meta.register",
      authorize: [],
    },
    component: () => import("@/components/patientOnboarding/PatientOnboardingInfo.vue"),
  },
  {
    path: "success",
    name: "registerSuccess",
    meta: {
      title: "meta.register",
      authorize: [],
    },
    component: () => import("@/components/patientOnboarding/PatientOnboardingSuccess.vue"),
  },
  {
    path: "error",
    name: "registerError",
    meta: {
      title: "meta.register",
      authorize: [],
    },
    component: () => import("@/components/patientOnboarding/PatientOnboardingError.vue"),
  },
];

export default onboardingRoutes;
