import { Component, Vue } from "vue-property-decorator";

@Component
export default class TitleMixin extends Vue {
  private get _appName(): string {
    return `${this.$t("community.name").toString()} ${this.$t("global.epr").toString()}`;
  }

  private get _docTitle(): string {
    if (this.$route.meta?.title) {
      return `${this.$t(this.$route.meta.title)} | ${this._appName}`;
    }
    return this._appName;
  }

  activated() {
    document.title = this._docTitle;
  }

  mounted() {
    document.title = this._docTitle;
  }
}
