
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class GenderIcon extends Vue {
  @Prop({ type: String, validator: (s) => ["male", "female", "other", "unknown"].includes(s) })
  private readonly gender!: string;

  @Prop({
    default: "24",
    validator: (s: string) =>
      !isNaN(Number(s)) ||
      (s.length >= 2 && !isNaN(Number(s.slice(0, s.length - 1))) && s.slice(-1) === "x"),
  })
  private readonly size!: string;

  private readonly malePath =
    "M800-800v240h-80v-103L561-505q19 28 29 59.5t10 65.5q0 92-64 156t-156 64q-92 0-156-64t-64-156q0-92 64-156t156-64q33 0 65 9.5t59 29.5l159-159H560v-80h240ZM380-520q-58 0-99 41t-41 99q0 58 41 99t99 41q58 0 99-41t41-99q0-58-41-99t-99-41Z";
  private readonly femalePath =
    "M440-120v-80h-80v-80h80v-84q-79-14-129.5-75.5T260-582q0-91 64.5-154.5T480-800q91 0 155.5 63.5T700-582q0 81-50.5 142.5T520-364v84h80v80h-80v80h-80Zm40-320q58 0 99-41t41-99q0-58-41-99t-99-41q-58 0-99 41t-41 99q0 58 41 99t99 41Z";
  private readonly otherPath =
    "M480-360q58 0 99-41t41-99q0-58-41-99t-99-41q-58 0-99 41t-41 99q0 58 41 99t99 41ZM440-40v-80h-80v-80h80v-84q-78-14-129-75t-51-141q0-33 9.5-65t28.5-59l-26-26-56 56-56-56 56-57-76-76v103H60v-240h240v80H197l76 76 57-56 56 56-56 57 26 26q27-20 59-29.5t65-9.5q33 0 65 9.5t59 29.5l159-159H660v-80h240v240h-80v-103L661-625q19 28 29 59.5t10 65.5q0 80-51 141t-129 75v84h80v80h-80v80h-80Z";
  private readonly unknownPath =
    "M424-320q0-81 14.5-116.5T500-514q41-36 62.5-62.5T584-637q0-41-27.5-68T480-732q-51 0-77.5 31T365-638l-103-44q21-64 77-111t141-47q105 0 161.5 58.5T698-641q0 50-21.5 85.5T609-475q-49 47-59.5 71.5T539-320H424Zm56 240q-33 0-56.5-23.5T400-160q0-33 23.5-56.5T480-240q33 0 56.5 23.5T560-160q0 33-23.5 56.5T480-80Z";

  get path(): string {
    switch (this.gender) {
      case "male":
        return this.malePath;
      case "female":
        return this.femalePath;
      case "other":
        return this.otherPath;
      default:
        return this.unknownPath;
    }
  }

  get calculatedSize(): string {
    return this.size.slice(-1) === "x"
      ? this.size.slice(0, this.size.length - 1) + "em"
      : parseInt(this.size) + "px";
  }
}
